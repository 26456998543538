import React from 'react';
import { FormattedMessage } from 'react-intl';

interface OverviewFooterProps {}

const OverviewFooter: React.FC<OverviewFooterProps> = () => {
    return (
        <div className="overview-footer row margin-top-20">
            <div className="bg-white padding-15 col-6">
                <h6 style={{ fontFamily: 'MANEUROPECONDENSED' }}>
                    <FormattedMessage id='intl-msg:business'/>
                </h6>
                <div className="row gap-10">
                    <div className="col-6">
                        <a href='https://confluence.collaboration-man.com/display/RIOCLAID/MDM+OTA+Documentation'
                           target='_blank'
                           rel='noopener noreferrer'>
                            <span className='text-primary'>
                                <FormattedMessage id='intl-msg:otaIntroduction'/>
                            </span>
                        </a>
                    </div>
                    <div className="col-6">
                        <a href='https://confluence.collaboration-man.com/display/MDM/MDM+Knowledge+Base'
                           target='_blank'
                           rel='noopener noreferrer'>
                            <span className='text-primary'>
                                <FormattedMessage id='intl-msg:otaKnowledgeBase'/>
                            </span>
                        </a>
                    </div>
                </div>
            </div>

            <div className="bg-white padding-15 col-6">
                <h6 style={{ fontFamily: 'MANEUROPECONDENSED' }}>
                    <FormattedMessage id='intl-msg:help'/>
                </h6>
                <div className="row gap-10">
                    <div className="col-6">
                        <a href='https://jira.collaboration-man.com/secure/RapidBoard.jspa?projectKey=MDMOPS&rapidView=1956'
                           target='_blank'
                           rel='noopener noreferrer'>
                            <span className='text-primary'>
                                <FormattedMessage id='intl-msg:createJiraTicket'/>
                            </span>
                        </a>
                    </div>
                    <div className="col-6">
                        <a href='https://my-rio.slack.com/archives/C015W6RRNAX'
                           target='_blank'
                           rel='noopener noreferrer'>
                            <span className='text-primary'>
                                <FormattedMessage id='intl-msg:mdmSupportChannel'/>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OverviewFooter;
