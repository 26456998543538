import React from 'react';
import VehicleVariantsSelect from "~/features/overviewNew/components/VehicleVariantsSelect";
import OverviewFooter from "~/features/overviewNew/components/OverviewFooter";

interface OverviewContainerProps {

}

const OverviewContainer: React.FC<OverviewContainerProps> = () => {

    return (
        <div className="overview">
            <VehicleVariantsSelect/>
            <OverviewFooter/>
        </div>
    );
};

export default OverviewContainer;
